const Footer = ({ note, page, setPageState }) => {
  console.debug({note})
  return (
    <footer className="mt-auto w-full">
    <div className="mt-24 border-t-2 border-black pt-4">
      <div className="text-right">
        {<NoteDetails note={note} page={page} setPageState={setPageState} />}
        {<ResetNote   note={note} page={page} setPageState={setPageState} />}
      </div>

      <div className="text-right">
        <div>
          <a href="/about">about</a> | <a href="mailto:hey@stqrs.com">contact</a>
        </div>

        <div>
          <span className="text-xs">
            made in somerville, ma with 🥃
          </span>
        </div>
      </div>
    </div>
    </footer>
  )
}

const NoteDetails = ({note, page, setPageState}) => {
  // TODO: abstract this link handler (used in ManageNote)
  function handleBack(e){
    // based on whether the Note.can_edit field is set on the note
    // set the page state to either:
    //  editor -> is authorized, show the full editor
    //  manage -> give options for authorizing to then edit
    let editState = note.can_edit ? 'editor' : 'manage'

    e.preventDefault();
    setPageState(editState);
  }

  if (!note || note.status !== "published" || page === 'editor'){ return null }

  return (
    <div>
      <button onClick={handleBack}><strong>manage note</strong></button>
    </div>
  )
}

const ResetNote = ({note, page, setPageState}) => {
  function handleClick(e){
    e.preventDefault();
    setPageState('reset')
  }

  if (!note || note.status !== "published" || page !== 'editor'){ return null }

  return (
    <div>
      <button onClick={handleClick}><strong>reset note</strong></button>
    </div>
  )
}

export default Footer;
