import './MenuBar.scss'
import StqrsAPI from './stqrs-api'

import React, { useRef, Fragment } from 'react'

import MenuItem from './MenuItem'

const MenuBar = ({ editor, note }) => {
  const items = [
    {
      icon: 'bold',
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: 'italic',
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: 'strikethrough',
      title: 'Strike',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
    },
    {
      icon: 'code-view',
      title: 'Code',
      action: () => editor.chain().focus().toggleCode().run(),
      isActive: () => editor.isActive('code'),
    },
    {
      icon: 'mark-pen-line',
      title: 'Highlight',
      action: () => editor.chain().focus().toggleHighlight().run(),
      isActive: () => editor.isActive('highlight'),
    },
    {
      type: 'divider',
    },
    {
      icon: 'h-1',
      title: 'Heading 1',
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive('heading', { level: 1 }),
    },
    {
      icon: 'h-2',
      title: 'Heading 2',
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive('heading', { level: 2 }),
    },
    {
      icon: 'paragraph',
      title: 'Paragraph',
      action: () => editor.chain().focus().setParagraph().run(),
      isActive: () => editor.isActive('paragraph'),
    },
    {
      icon: 'list-unordered',
      title: 'Bullet List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
    },
    {
      icon: 'list-ordered',
      title: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
    },
    {
      icon: 'list-check-2',
      title: 'Task List',
      action: () => editor.chain().focus().toggleTaskList().run(),
      isActive: () => editor.isActive('taskList'),
    },
    {
      icon: 'code-box-line',
      title: 'Code Block',
      action: () => editor.chain().focus().toggleCodeBlock().run(),
      isActive: () => editor.isActive('codeBlock'),
    },
    {
      type: 'divider',
    },
    {
      icon: 'double-quotes-l',
      title: 'Blockquote',
      action: () => editor.chain().focus().toggleBlockquote().run(),
      isActive: () => editor.isActive('blockquote'),
    },
    {
      icon: 'separator',
      title: 'Horizontal Rule',
      action: () => editor.chain().focus().setHorizontalRule().run(),
    },
    {
      type: 'divider',
    },
    {
      icon: 'text-wrap',
      title: 'Hard Break',
      action: () => editor.chain().focus().setHardBreak().run(),
    },
    {
      icon: 'format-clear',
      title: 'Clear Format',
      action: () => editor.chain().focus().clearNodes().unsetAllMarks()
        .run(),
    },
    {
      type: 'divider',
    },
    {
      icon: 'arrow-go-back-line',
      title: 'Undo',
      action: () => editor.chain().focus().undo().run(),
    },
    {
      icon: 'arrow-go-forward-line',
      title: 'Redo',
      action: () => editor.chain().focus().redo().run(),
    },
    {
      icon: 'arrow-go-forward-line',
      title: 'focus',
      action: () => editor.chain().focus().run(),
    },
    {
      type: 'divider'
    },
    {
      icon: 'image-add-line',
      title: 'Upload Image',
      action: () => uploadHandler()
    },
    {
      icon: 'skip-down-line',
      title: 'test',
      action: () => editor.chain().scrollIntoView().focus().run(),
    }
  ]

  // Add reference to hidden file upload form and associated
  // upload handler to delegate the 'click' from the toolbar -> form
  const hiddenFileInput = useRef(null);
  const uploadHandler = () => { hiddenFileInput.current.click() };

  // Upload delegate to StqrsAPI
  const upload = (attachment) => {
    StqrsAPI.addAttachment(note.uuid, attachment, renderUpload);
  }

  // Callback for StqrsAPI.addAttachment for when file upload is successful
  const renderUpload = (data) => {
    console.debug({data});
    if (!data && !data.attachment_uri){
      console.error("No upload response / data uri, returning");
      return;
    }

    switch(data.content_category){
      case 'image':
        handleImage(data);
        break;
      case 'video':
        handleVideo(data);
        break;
      default:
        console.error(`Unknown content category:${data.content_category}`);
    }
  }

  const handleVideo = (data) => {
    editor.chain().createParagraphNear().run();
    editor.chain().focus().setVideo(data.attachment_uri).enter().focus('end').run();
    editor.chain().enter().createParagraphNear().run()
    editor.chain().scrollIntoView().focus('end').run();
  }

  const handleImage = (data) => {
    // TODO: add handling for different filetypes
    // NOTE: in order to properly set the pointer/focus after image upload
    //       we must first download the image then perform the editor updates
    //       attempting to update the editor with a setImage -> focus
    //       seemed to not work as the image element would be inserted,
    //       the focus commands would be called, then the image would actually be
    //       rendered, causing the focus to be invalid
    let img = new Image();
    img.onload = () => {
      editor.chain().createParagraphNear().run();
      editor.chain().focus().setImage({ src: data.attachment_uri }).enter().focus('end').run();
      editor.chain().enter().createParagraphNear().run()
      editor.chain().scrollIntoView().focus('end').run();
    }
    img.src = data.attachment_uri
  }

  // Local file upload handler, delegates to uploader on change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return
    upload(e.target.files[0])
  }


  return (
    <div className="editor__header">
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.type === 'divider' ? <div className="divider" /> : <MenuItem {...item} />}
        </Fragment>
      ))}

      <label className='inline-block px-2 cursor-pointer' htmlFor='upload'>
        <input
          className='hidden'
          ref={hiddenFileInput}
          id='upload'
          type='file'
          onChange={handleChange}
          accept='image/*;capture=camera'
        />
      </label>
    </div>
  )
}


export default MenuBar
