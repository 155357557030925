import StqrsEditor from '../stqrs-editor.js'
import { useEffect } from 'react'

import Header from '../Header'
import Footer from '../Footer'
import TipTap from '../TipTap'

// TODO: look into passing instantiated editor into this component
const Content = ({ note, setPageState }) => {
  console.debug(note);
  useEffect(() => {
    document.title = 'stqrs';
  }, [])
  const editor = StqrsEditor({autofocus: false});
  if (!editor) { return }

  return (
    <div className="p-4 h-screen">
      <Header />

      <TipTap editor={editor} editable={false} content={note.content} />

      <Footer note={note} setPageState={setPageState} />
    </div>
  )
}

export default Content;
