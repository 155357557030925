const Header = () => {
  return (
    <header>
      <a href="https://stqrs.com" target="_blank" rel="noreferrer">
        <div className="align-baseline mb-2 text-right">
          <img alt="logo" className="inline h-6 w-6" src="/images/logo.png"></img>
          <span>stqrs</span>
        </div>
      </a>
    </header>
  )
}

export default Header
