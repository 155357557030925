class StqrsHelpers {
  static parseURI(){
    return {
      uuid: this.getUUID(),
      params: this.getURLParams()
    }
  }

  static getUUID(){
    let noteURI = window.location.pathname;
    let uuid = noteURI.split('/').reverse()[0];
    console.debug(noteURI);
    console.debug(uuid)

    if (uuid.length === 0){ return false }
    return uuid;
  }

  static getURLParams(){
    const url = new URL(window.location.href);
    const params = new Proxy(url.searchParams, {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    return params;
  }


  // helper to get an available JWT for the client
  // NOTE: this helper has no context of the JWT itself
  //       (wrt if it is for account linking or account auth)
  //       for this reason, a URI JWT will always take precedence
  //       over a localStorage JWT
  static getJWT(){
    // if the JWT is present in the URI, clear it out of the URI
    // and return the JWT value
    let uriJWT = this.getURIJWT();
    if (uriJWT){
      console.log("Found JWT in URI");
      this.clearURIJWT()
      this.setLocalStorageJWT(uriJWT);
      console.log(localStorage.getItem("stqrs_jwt"));
      return uriJWT;
    }

    // otherwise, check in localStorage for the JWT
    console.log("No URI JWT, attempting localStorage retrieval")
    let localStorageJWT = this.getLocalStorageJWT();
    return localStorageJWT;
  }

  static getURIJWT(){
    let uriParams = this.parseURI()
    let uriJWT = uriParams.params.jwt;
    return uriJWT;
  }

  static clearURIJWT(){
    const url = new URL(window.location.href);
    url.searchParams.delete('jwt');
    window.history.replaceState({}, document.title, url.href)
  }

  static getLocalStorageJWT(){
    let localStorageJWT = localStorage.getItem("stqrs_jwt");
    return localStorageJWT;
  }

  static setLocalStorageJWT(jwt){
    localStorage.setItem("stqrs_jwt", jwt);
    return true;
  }
}

export default StqrsHelpers;
