import StqrsAPI from '../stqrs-api'
import React, { useState, useRef } from 'react'
import ReactGA from "react-ga4";

const SendMagicManageLink = ({note}) => {
  const [emailSent, setEmailSent] = useState(false);
  const emailRef = useRef(null)

  function emailManageLink(e){
    e.preventDefault();
    let email = emailRef.current.value;
    console.log(email);
    console.log(note);

    ReactGA.event({
      category: "note:manage",
      action: "magic_link_email_sent"
    });

    StqrsAPI.sendMagicLink(note.uuid, email);
    setEmailSent(true);
  }

  if (emailSent){
    return (
      <div>
        an edit link has been sent to <strong>{emailRef.current.value}</strong>!
      </div>
    )
  }

  return (
    <div>
      <div className="text-center mb-6">
        <span>
          Enter the email associated with your note.
          You will receive a link to edit the note.
        </span>

        <br />
        <br />

        <form onSubmit={emailManageLink}>
          <div className="text-center grid place-items-center mb-6">
            <input type="email" placeholder="email" ref={emailRef} autoFocus
            className="text-center w-80 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          ></input>
          </div>
          <button className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 text-center center"
            onClick={ (e) => emailManageLink(e) }
          >
            Send Edit Link
          </button>
        </form>
      </div>
    </div>
  )
}



export default SendMagicManageLink
