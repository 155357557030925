class StqrsAPI {
  static BASE_URL = process.env.REACT_APP_API_BASE_URL;

  static setJWT(jwt){
    this.jwt = jwt;
  }

  static getHeaders(){
    let headers = {
      'X-Stqrs-Client-Version': "1.0"
    };

    if (this.jwt){
      headers['X-Stqrs-JWT'] = this.jwt;
    }
    return headers;
  }

  static async getNote(uuid, callback){
    let getNoteURL = this.getURL(`/api/v1/notes/${uuid}`);
    console.log(getNoteURL);
    let response = await fetch(getNoteURL, {
      method: "GET",
      headers: this.getHeaders()
    })

    let data = await response.json();
    if (callback) { callback(response) }
    return data;
  }

  static updateNote(uuid, data, callback = undefined){
    let payload = {
      content: data
    };
    let updateNoteURL = this.getURL(`/api/v1/notes/${uuid}/update`);
    console.log(updateNoteURL);
    fetch(updateNoteURL, {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(payload)
    })
      .then( (response) => response.json() )
      .then( (data) => {
        if (callback) { callback(data) }
      });
  }

  // TODO: combine with updateNote with "publish" flag
  static publishNote(uuid, content, callback){
    let payload = {
      content: content
    };
    let publishNoteURL = this.getURL(`/api/v1/notes/${uuid}/publish`);
    console.log(publishNoteURL);
    fetch(publishNoteURL, {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(payload)
    })
      .then( (response) => response.json() )
      .then( (data) => {
        if(callback) { callback(data) }
      });
  }

  static linkAccountToNote(uuid, jwt, email, callback = undefined){
    let payload = { email: email };
    let linkURL = this.getURL(`/api/v1/notes/${uuid}/link_account`);
    console.log(linkURL);

    // for this specific request, we will need to use the "note jwt" that
    // comes back from the publish response - it is a scoped note jwt
    // that can be used to perform the link operation
    let headers = this.getHeaders();
    headers['X-Stqrs-JWT'] = jwt;

    fetch(linkURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then( (response) => response.json() )
      .then( (data) => {
        if(callback) { callback(data) }
      });
  }

  static sendMagicLink(uuid, email, callback = undefined){
    let payload = { email: email};
    let linkURL = this.getURL(`/api/v1/notes/${uuid}/send_manage_link`);
    console.log(linkURL);
    fetch(linkURL, {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(payload)
    })
      .then( (response) => response.json() )
      .then( (data) => {
        if(callback) { callback(data) }
      });
  }

  static resetNote(uuid, callback = undefined){
    let linkURL = this.getURL(`/api/v1/notes/${uuid}/reset`);
    console.log(linkURL);
    fetch(linkURL, {
      method: "POST",
      headers: this.getHeaders()
    })
      .then( (response) => response.json() )
      .then( (data) => {
        if(callback) { callback(data) }
      });
  }

  static addAttachment(uuid, attachment, callback = undefined){
    let attachmentURL = this.getURL(`/api/v1/notes/${uuid}/attachments`);
    console.log(attachmentURL)


    let data = new FormData();
    data.append('file', attachment)

    fetch(attachmentURL, {
      method: "POST",
      headers: this.getHeaders(),
      body: data
    })
      .then( (response) => response.json() )
      .then( (data) => {
        if(callback) { callback(data) }
      });
  }


  static getURL(path){
    console.log(this.BASE_URL);
    return `${this.BASE_URL}${path}`;
  }
}


export default StqrsAPI;
