import StqrsAPI from '../stqrs-api'
import React, { useState, useRef } from 'react'
import ReactGA from "react-ga4";


const SendEmail = ({ note }) => {
  const [emailSent, setEmailSent] = useState(false);
  const emailRef = useRef(null);

  console.log(note);

  function emailNote(e){
    e.preventDefault();
    let email = emailRef.current.value;
    console.log(email);
    console.log(note);

    StqrsAPI.linkAccountToNote(note.uuid, note.note_jwt, email);

    ReactGA.event({
      category: "note:publish",
      action: "email_sent"
    });


    setEmailSent(true);
  }

  if (emailSent){
    return (
      <div>
        an email confirmation is on its way to <strong>{emailRef.current.value}</strong>!<br /><br />
        make sure you save it so that you can access your stqr in the future
      </div>
    )
  }

  return (
    <div>
      <div className="text-center mb-6 mt-12">
        <h3>Want to edit your stqr?</h3>
        <span>Enter your email below:</span>
      </div>

      <form onSubmit={emailNote}>
        <div className="text-center grid place-items-center mb-6">
          <input type="email" placeholder="email" ref={emailRef} autoFocus
          className="text-center w-80 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        ></input>
        </div>
        <button className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 text-center center"
          onClick={ (e) => emailNote(e) }
        >
          Confirm Email
        </button>
      </form>
    </div>
  )
}

export default SendEmail
