import Header from '../Header'
import Footer from '../Footer'
import TipTap from '../TipTap'

import SetPreviewButton from './SetPreviewButton'

const Edit = ({ editor, note, setPreview, setPageState}) => {


  return(
    <div className="p-4 flex flex-col h-screen">
      <Header />

      <content>
        <h2>setup</h2>

        <TipTap
          editor={editor}
          editable={true}
          content={note.content}
          note={note}
        />

        <SetPreviewButton
          previewFunction={setPreview}
        />
      </content>

      <Footer note={note} setPageState={setPageState} page={'editor'}/>
    </div>
  )
}

export default Edit;
