import Button from '../Button'

const SetPreviewButton = ({previewFunction}) => {
  return (
    <div className="text-center mt-6">
      <Button text={"Preview"} callback={previewFunction} />
    </div>
  )
}

export default SetPreviewButton;
