import './tailwind.css'
import './styles.scss'

import StqrsHelpers from './stqrs-helpers'
import StqrsAPI from './stqrs-api'

import React, { useState, useEffect } from 'react'

import Content from './Content/Content'
import Editor from './Editor/Editor'
import ManageNote from './ManageNote/ManageNote'
import ResetNote from './ManageNote/ResetNote'


function App (props) {
// NOTE: currently, the editor (TipTap/StqrsEditor) is initialized
//       inside the Editor/Content component, which simplifies modifications
//       based on the individual components state
//       it does, however, cause the subcomponents (header/footer) to be rerendered
//       each time there is a change to the editor (not sure why, this is a bug)
//       instantiating a single editor (TipTap/StqrsEditor) in the main app and passing
//       it around seems to resolve this issue and may have some other perf benefits
//       but avoiding that to keep subcomponent state simpler for the time being
//  const editor = StqrsEditor();

  const [pageState, setPageState] = useState();
  const [pageDetails, setPageDetails] = useState();
  const [note, setNote] = useState();
  const [ready, setReady] = useState(false);

  function getStateComponent(){
    switch(pageState){
      case 'content':
        window.history.pushState(null, null, '#');
        return Content
      case 'editor':
        window.history.pushState(null, null, '#edit');
        return Editor
      case 'manage':
        window.history.pushState(null, null, '#manage');
        return ManageNote
      case 'reset':
        window.history.pushState(null, null, '#reset');
        return ResetNote
      default:
        console.log("Unknown state, returning fallback")
        return null;
    }
  }

  function getPageState(note){
    if (note.status === "draft") { return 'editor' }
    if (note.status === "published") { return 'content' }
    return null;
  }

  // TODO: this logic should probably be consolidated as it lives
  //       here as well as footer - routing in general needs some tlc
  function getInitialPageState(note){
    // on the first load, we should look at the hash component
    // to determine routing
    let hash = window.location.hash.replace('#', '');

    let state = '';
    switch(hash){
      case 'manage':
        if (note.can_edit){
          state = 'editor'
          break;
        }
        state = 'manage';
        break;
      case 'edit':
        if (!note.can_edit){
          state = 'manage';
          break;
        }
        state = 'editor';
        break;
      case 'reset':
        if (note.status === "draft"){
          state = 'editor';
          break;
        }
        state = 'reset'
        break;
      default:
        if (note.status === "draft"){
          state = 'editor';
          break;
        }
        state = 'content';
        break
    }

    return state;
  }

  useEffect(() => {
    document.title = 'stqrs';
    async function configure() {
      let pageDetails = getPageDetails()

      // attempt to retrieve a JWT, and if one is available
      // set it in the StqrsAPI singleton class
      let jwt = StqrsHelpers.getJWT();
      if (jwt){ StqrsAPI.setJWT(jwt); }

      let data = await getNote(pageDetails);

      setPageDetails(pageDetails);
      setNote(data);

      let initialPageState = getInitialPageState(data);
      setPageState(initialPageState);
      setReady(true);
    }
    configure()
  }, [])

  // this hook is used for child components to signal a reset
  // of the page state - child components will set pageState
  // to null to trigger this hook and determine what should be
  // rendered
  useEffect(() => {
    // if page isn't ready (details or note undefined) skip this hook
    if (!ready) { return };

    // if the pageState is already defined, skip this hook
    // the state has already been set and doesn't need to be updated
    if (pageState) { return }
    let initialPageState = getPageState(note);
    setPageState(initialPageState);
  }, [pageState, note, ready])

  // if we don't have page details or a valid uuid, this page is invalid
  // and we should avoid attempting to render any note contents
  if (!pageDetails || !pageDetails.uuid){
    // TODO: render welcome page or redirect
    console.log("Invalid pageDetails/UUID - ending render");
    return;
  }

  // if no note is available (yet?), skip rendering until ready
  if (!note){
    console.log("No note available, skipping rendering until ready");
    return;
  }

  if (!pageState){
    console.log("PageState not available, skipping rendering until ready");
    return
  }

  console.log("pageDetails and Note available, rendering");
  console.log({pageDetails});
  console.log({note});

  if (!ready){ return }
  console.log({ready});

  let Component = getStateComponent();

  return (
    <div className="container mx-auto">
      <Component note={note} setPageState={setPageState}/>
    </div>
  )
}

function getPageDetails(){
  const noteDetails = StqrsHelpers.parseURI();
  return noteDetails;
}

async function getNote(pageDetails){
  console.log(`Getting note:${pageDetails.uuid}`);
  let note = await StqrsAPI.getNote(pageDetails.uuid)
  console.debug({note});
  return note;
}

export default App;
