import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Video from './extensions/Video'

function StqrsEditor(opts){
  let autofocus = (opts || {}).autofocus || true;
  let editor = useEditor({
    extensions: [
      StarterKit,
      Highlight,
      TaskList,
      TaskItem,
      Image,
      Video,
      Placeholder.configure({
        placeholder: ({ node }) => {
          return "Click here to get started!";
        },
      }),
      Link.configure({
        protocols: ['http', 'https', 'mailto'],
        HTMLAttributes: {
          class: 'font-bold text-blue-600 dark:text-blue-500 hover:underline font-weight-800 decoration-2'
        },
      }),
    ],
    autofocus: autofocus,
    editable: false,
    document: false,
    content: ``
  });

  return editor
}

export default StqrsEditor;
