import { useEffect } from 'react'
import { EditorContent } from '@tiptap/react'

import MenuBar from './MenuBar'

const TipTap = ({editor, note, editable, content}) => {
  if (!content || content.length === 0){ content = "" }
  useEffect(() => {
    if (!editor) { return }
    editor.commands.setContent(content);
    editor.setEditable(editable)

    if (editable){ editor.commands.focus(); }
  }, [editor, content, editable])

  if (!editor) { return }
  let editableCSS = editable ? '' : 'preview';

  return (
    <div className={`editor ${editableCSS}`}>
      {editable && <MenuBar editor={editor} note={note} />}
      <EditorContent className="editor__content" editor={editor} />
    </div>
  )
}

export default TipTap;
