import { useEffect } from 'react'

import Header from '../Header'
import Footer from '../Footer'

import SendMagicManageLink from './SendMagicManageLink'

const ManageNote = ({note, setPageState}) => {
  // TODO: abstract this link handler (used in Footer)
  function handleBack(e){
    e.preventDefault();
    setPageState();
  }

  useEffect(() => {
    document.title = 'stqrs - manage note';
  }, [])

  return (
    <div className="p-4 flex flex-col h-screen">
      <Header />

      <h1>manage note</h1>
      <h4><button onClick={handleBack}>← back to note</button></h4>

      <SendMagicManageLink note={note} />

      <Footer />
    </div>
  )
}

export default ManageNote;
