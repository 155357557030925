import StqrsAPI from '../stqrs-api'
import { useEffect } from 'react'

import Header from '../Header'
import Footer from '../Footer'
import Button from '../Button'

const ResetNote = ({note, setPageState}) => {
  // TODO: abstract this link handler (used in Footer)
  function handleBack(e){
    e.preventDefault();
    setPageState('editor');
  }

  function resetNote(e){
    StqrsAPI.resetNote(note.uuid, () => { window.location.reload() })
  }

  useEffect(() => {
    document.title = 'stqrs - reset note';
  }, [])

  return (
    <div className="p-4 flex flex-col h-screen">
      <Header />

      <h1>reset note</h1>
      <h4><button onClick={handleBack}>← back to note</button></h4>

      <div className="text-center">
        <h3>Warning!!</h3>
        <span>
          This action will <strong>permanently</strong> delete this note
          and reset it for reuse - this should be used for testing only!
        </span>


        <h3>Are you sure you want to reset the note?</h3>
        <Button text={"yes, reset note"} callback={resetNote} />
      </div>


      <Footer />
    </div>
  )
}

export default ResetNote;
