import Header from '../Header'
import Footer from '../Footer'

import TipTap from '../TipTap'

import PreviewBanner from './PreviewBanner'

const Preview = ({ editor, note, setPageState, editFunction, publishFunction }) => {
  const alreadyPublished = note.status === 'published'
  return (
    <div>
      <PreviewBanner
        alreadyPublished={alreadyPublished}
        editFunction={editFunction}
        publishFunction={publishFunction}
      />

      <div className="p-4 flex flex-col">
        <Header />

        <content>
          <TipTap
            editor={editor}
            editable={false}
            content={note.content}
          />


        </content>

        <Footer note={note} setPageState={setPageState} page={'editor'}/>
      </div>
    </div>
  )
}

export default Preview;
