import Header from '../Header'
import Footer from '../Footer'
import SendEmail from './SendEmail'


const JustPublished = ({ note }) => {
  // TODO: source base url from config
  let url = `https://app.stqrs.com/notes/${note.uuid}`;
  console.log({note})
  return (
    <div className="p-4 flex flex-col h-screen">
      <Header />
      <div className="text-center mt-24">
        <h1>Your stqr is live!</h1>
        <span>
          Congratulations, your <a
            href={url}
            rel="noreferrer"
            target="_blank"
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          >
            stqr
          </a> is now published! When someone scans your QR code, they will see your note.
        </span>

        <br /><br />

        <SendEmail note={note} />

      </div>
      <Footer />
    </div>
  )
}


export default JustPublished;
