import StqrsEditor from '../stqrs-editor'
import StqrsAPI from '../stqrs-api'
import StqrsHelpers from '../stqrs-helpers'

import ReactGA from "react-ga4";
import { useEffect, useState } from 'react'

import Preview from './Preview'
import Edit from './Edit'
import JustPublished from './JustPublished'

const Editor = ({ note, setPageState }) => {
  const [publishedNote, setPublishedNote] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [justPublished, setJustPublished] = useState(false);
  const alreadyPublished = note.status === 'published'

  useEffect(() => {
    document.title = 'stqrs - editor';
  }, [])

  console.debug({note});
  const editor = StqrsEditor();
  if (!editor) { return }
  console.debug({editor})

  function setPreview(e){
    let contentJSON = editor.getJSON();
    StqrsAPI.updateNote(note.uuid, contentJSON);
    // TODO: look into whether we should update the entire note
    //       through the updatNote callback
    note.content = contentJSON;

    ReactGA.event({
      category: "note:publish",
      action: "note_updated"
    });

    setShowPreview(true);
  }

  function setEdit(e){
    setShowPreview(false);
  }

  function publishNote(e){
    if (alreadyPublished){
      setPageState('content');
      return;
    }

    let contentJSON = editor.getJSON();
    StqrsAPI.publishNote(note.uuid, contentJSON, (data) => {
      console.log(data);
      setPublishedNote(data);
      StqrsHelpers.setLocalStorageJWT(data.note_jwt);
      StqrsAPI.setJWT(data.note_jwt);
      ReactGA.event({
        category: "note:publish",
        action: "note_published"
      });

      setJustPublished(true);
    })
  }


  if (justPublished){
    if (alreadyPublished){ setPageState('content'); return }

    return (
      <JustPublished note={publishedNote}  />
    )
  }


  if (showPreview){
    return (
      <Preview
        editor={editor}
        note={note}
        setPageState={setPageState}
        editFunction={setEdit}
        publishFunction={publishNote}
      />
    )
  }

  return (
    <Edit
      editor={editor}
      note={note}
      setPageState={setPageState}
      setPreview={setPreview}
    />
  )
}

export default Editor;
