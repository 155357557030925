import Button from '../Button'

const PreviewBanner = ({alreadyPublished, editFunction, publishFunction}) => {
  const publishedText = alreadyPublished ? "Done" : "Publish"

  return (
    <div className="sticky top-0 z-30 w-full bg-black border-dotted border-b-2 mb-4 pb-2">
      <div className="text-center pt-6">
        <Button text={"Edit"}        light={false} callback={editFunction} />
        <Button text={publishedText} light={false} callback={publishFunction} />
      </div>
      <div className="text-center">
        <span className="text-center text-xs text-white">here is what you'll see when scanning the qr code</span>
      </div>
    </div>
  )
}

export default PreviewBanner;
